import React, { useState, useEffect } from "react";
import BlurBackground from "../Reuse/BlurBackground";
import Modal from "../Reuse/Modal";
import { API_URL } from "../Reuse/Common";
import Loader from "../Reuse/Loader";
import WorkCard from "../Cards/WorkCard";
import Pagination from '../utils/Pagination';

const WorkDisplay = () => {
    const [showImageModal, setShowImageModal] = useState(false);
    const [imgData, setImgData] = useState("");
    const [works, setWorks] = useState([]);
    const [showLoadingModal, setShowLoadingModal] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [activeTab, setActiveTab] = useState('graphic-design');

    const fetchWork = async (category, page = 1) => {
        try {
            const response = await fetch(`${API_URL}/${category}?page=${page}`);
            const data = await response.json();
            if (response.ok && data.data) {
                setWorks(data.data);
                setCurrentPage(data.current_page);
                setTotalPages(Math.ceil(data.total / data.per_page));
            } else {
                setError("Failed to fetch works");
            }
        } catch (err) {
            setError("An error occurred while fetching the data");
        } finally {
            setShowLoadingModal(false);
        }
    };

    useEffect(() => {
        let tabTitle = "";
        switch (activeTab) {
            case "graphic-design":
                tabTitle = "Graphic Design";
                break;
            case "ui-ux-design":
                tabTitle = "UI/UX Design";
                break;
            case "web-developments":
                tabTitle = "Web Developments";
                break;
            default:
                tabTitle = "Work Display";
        }
        document.title = `${tabTitle} | Kikimo Developers`;
        fetchWork(activeTab); // Fetch work for the selected category
    }, [activeTab]);

    const handleViewImageModal = (imageSrc) => {
        setImgData(imageSrc);
        setShowImageModal(true);
    };

    const handleCloseModal = () => {
        setShowImageModal(false);
        setImgData("");
    };

    const handlePageClick = (pageNumber) => {
        if (pageNumber !== currentPage) {
            fetchWork(activeTab, pageNumber);
        }
    };

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
            <div className="tabs flex justify-center mb-5">
                <button
                    className={`tab-button px-4 py-2 mx-2 rounded ${activeTab === 'graphic-design' ? 'bg-red-600 text-white' : 'bg-gray-200 text-gray-700'
                        }`}
                    onClick={() => setActiveTab('graphic-design')}
                >
                    Graphic Design
                </button>
                <button
                    className={`tab-button px-4 py-2 mx-2 rounded ${activeTab === 'ui-ux-design' ? 'bg-red-600 text-white' : 'bg-gray-200 text-gray-700'
                        }`}
                    onClick={() => setActiveTab('ui-ux-design')}
                >
                    UI/UX Design
                </button>
                <button
                    className={`tab-button px-4 py-2 mx-2 rounded ${activeTab === 'web-developments' ? 'bg-red-600 text-white' : 'bg-gray-200 text-gray-700'
                        }`}
                    onClick={() => setActiveTab('web-developments')}
                >
                    Web Development
                </button>
            </div>

            <BlurBackground
                layout={
                    <div>
                        {showLoadingModal && <Loader />}

                        {error && <p className="text-red-500">{error}</p>}

                        <div className="flex flex-wrap items-center overflow-x-auto overflow-y-hidden py-2 justify-center text-gray-800">
                            {works.map((data) => (
                                <WorkCard
                                    key={data.id}
                                    data={data}
                                    handleViewImageModal={handleViewImageModal}
                                />
                            ))}
                        </div>

                        {/* Pagination Component */}
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageClick}
                        />
                    </div>
                }
            />

            {showImageModal && (
                <Modal
                    layout={
                        <>
                            <img className="rounded h-80" src={imgData} alt="Modal" />
                        </>
                    }
                    handleCloseModal={handleCloseModal}
                />
            )}
        </>
    );
};

export default WorkDisplay;
