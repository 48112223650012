const funGetDateDiff = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInMs = now - date;
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInDays >= 365) {
        const years = Math.floor(diffInDays / 365);
        return `${years} year(s) ago`;
    } else if (diffInDays >= 30) {
        const months = Math.floor(diffInDays / 30);
        return `${months} month(s) ago`;
    } else if (diffInDays >= 7) {
        const weeks = Math.floor(diffInDays / 7);
        return `${weeks} week(s) ago`;
    } else {
        return `${diffInDays} day(s) ago`;
    }
};

export default funGetDateDiff;
