// Login.js
import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { API_URL } from "../Reuse/Common";

const Login = () => {
    const { setToken } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        const response = await fetch(`${API_URL}/api/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password }),
        });
        const data = await response.json();
        if (response.ok) {
            const token = data.token; 
            setToken(token);
            localStorage.setItem('token', token);
            console.log("Token set:", token); 
            window.location.href = "/dashboard";
        } else {
            console.error("Login failed:", data.message);
        }
    };

    return (
        <div>
            <h2>Login</h2>
            <input
                type="text"
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>Login</button>
        </div>
    );
};

export default Login;
