import React from 'react';
import bgImg from '../../assets/mybackground.jpeg';

const BlurBackground = ({ layout }) => {
    return (
        <div
            id="services"
            className="bg-cover w-full flex justify-center items-center"
            style={{ backgroundImage: `url(${bgImg})` }}
        >
            <div className="w-full bg-white p-5 bg-opacity-40 backdrop-filter backdrop-blur-lg">
                <div className="w-12/12 mx-auto rounded-2xl bg-white p-5 bg-opacity-40 backdrop-filter backdrop-blur-lg">
                    {layout}
                </div>
            </div>
        </div>
    );
};

export default BlurBackground;
