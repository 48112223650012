import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    if (totalPages <= 1) return null; // No need to render pagination if only 1 page

    const handlePageClick = (pageNumber) => {
        if (pageNumber !== currentPage) {
            onPageChange(pageNumber);
        }
    };

    return (
        <div className="flex justify-between items-center py-4 bg-white p-4 rounded-lg">
            {/* Previous Page */}
            {currentPage > 1 && (
                <button
                    className="px-4 py-2 bg-gray-200 rounded-md"
                    onClick={() => handlePageClick(currentPage - 1)}
                >
                    Prev
                </button>
            )}

            {/* Page Numbers */}
            <div className="flex space-x-2">
                {Array.from({ length: totalPages }, (_, idx) => (
                    <button
                        key={idx}
                        className={`px-4 py-2 rounded-md ${currentPage === idx + 1 ? 'bg-red-500 text-white' : 'bg-gray-200'}`}
                        onClick={() => handlePageClick(idx + 1)}
                    >
                        {idx + 1}
                    </button>
                ))}
            </div>

            {/* Next Page */}
            {currentPage < totalPages && (
                <button
                    className="px-4 py-2 bg-gray-200 rounded-md"
                    onClick={() => handlePageClick(currentPage + 1)}
                >
                    Next
                </button>
            )}
        </div>
    );
};

export default Pagination;
