import React from 'react';

const Title = ({title, description }) => {
    return (
        <div className="py-12 bg-white">
        <h1 className="text-center text-3xl font-semibold capitalize text-gray-900 lg:text-4xl">
        {title}
        </h1>
        <div className="mx-auto mt-6 flex justify-center">
            <span className="inline-block h-1 w-1 rounded-full bg-red-700"></span>
            <span className="mx-1 inline-block h-1 w-3 rounded-full bg-red-700"></span>
            <span className="inline-block h-1 w-40 rounded-full bg-red-700"></span>
            <span className="mx-1 inline-block h-1 w-3 rounded-full bg-red-700"></span>
            <span className="inline-block h-1 w-1 rounded-full bg-red-700"></span>
        </div>
        <h1 className="text-center text-2xl font-base capitalize text-gray-500 lg:text-2xl mt-6">
            <span className="block xl:inline">{description}</span>
        </h1>
    </div>
    );
};

export default Title;
