import React, { useState, useEffect } from 'react';
import { API_URL } from '../Reuse/Common';
import funGetDateDiff from '../utils/funGetDateDiff';
import { useParams } from 'react-router-dom';

const MainBlog = () => {
    const { slug } = useParams();
    const [post, setPost] = useState([]);
    const [showLoadingModal, setShowLoadingModal] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch(`${API_URL}/blog/${slug}`);
                const data = await response.json();
                if (data) {
                    setPost(data);
                    document.title = `${data.title} | Kikimo Developers`;
                } else {
                    setError('Failed to fetch posts');
                }
            } catch (err) {
                setError('An error occurred while fetching the data');
            } finally {
                setShowLoadingModal(false);
            }
        };

        fetchPosts();
    }, [slug]);


    return (
        <div>
                    <div>
                        {showLoadingModal && (
                            <div class="mx-auto pb-4">
                                <div class="p-4 bg-white border border-primary rounded-md">
                                    <div class="flex">
                                        <div class="mr-4 bg-gray-200 border border-gray-200 h-16 w-16 rounded animate-pulse"></div>
                                        <div class="space-y-1 flex flex-col w-full">
                                            <div class="flex w-full items-center">
                                                <div class="bg-gray-200 border border-gray-200 w-60 h-5 animate-pulse"></div>
                                                <div class="ml-4 bg-ternary w-12 h-5 animate-pulse"></div>
                                            </div>
                                            <div class="bg-gray-200 border border-gray-200 w-36 h-5 animate-pulse"></div>
                                            <div class="bg-gray-200 border border-gray-200 w-full h-44 animate-pulse">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-4 flex items-center justify-between">
                                        <div class="flex items-center gap-2">
                                            <div class="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                                            <span class="bg-tertiary h-1 w-1 rounded animate-pulse"></span>
                                            <div class="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                                        </div>
                                        <div class="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {error && <p className="text-red-500">{error}</p>}

                        {!showLoadingModal && (
                            <section class="px-2 py-2 container mx-auto bg-transparent rounded-xl">
                                <div class="flex w-full h-full flex-wrap overflow-hidden rounded">
                                    <div class="w-full md:w-2/6 lg:w-2/6 p-2">
                                        <img class="object-cover h-full w-full rounded-lg"
                                            src={post.imageData ? post.imageData : `https://staging.kikimodev.com/Image/${post.cover_image}`} />
                                    </div>

                                    <div class="sm:w-8/12 p-5">
                                        <div class="space-y-2">
                                            <div class="space-y-4">
                                                <h4 class="text-2xl font-bold text-center text-cyan-900">
                                                    {post.title}
                                                </h4>
                                            </div>

                                            <div class="flex items-center space-x-4 justify-between">
                                                <div class="flex gap-3 space-y-1">
                                                    <img src={post.imageData ? post.imageData : `https://staging.kikimodev.com/Image/${post.cover_image}`} class="rounded-full h-8 w-8" />
                                                    <span class="text-sm">{post.name}</span>
                                                </div>

                                                <div class=" px-3 py-1 rounded-lg flex space-x-2 flex-row">
                                                    <div class="cursor-pointer text-center text-md justify-center items-center flex">
                                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024"
                                                            height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" class="text-md">
                                                            <path
                                                                d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8 264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0 679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z">
                                                            </path>
                                                        </svg>
                                                        <span class="text-md mx-1 ">{post.likes_count}</span>
                                                    </div>
                                                    <div class="cursor-pointer text-center text-md justify-center items-center flex">
                                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                                                            height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" class="text-md">
                                                            <path
                                                                d="M20 2H4c-1.103 0-2 .897-2 2v18l5.333-4H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm0 14H6.667L4 18V4h16v12z">
                                                            </path>
                                                            <circle cx="15" cy="10" r="2"></circle>
                                                            <circle cx="9" cy="10" r="2"></circle>
                                                        </svg>
                                                        <span class="text-md mx-1">{post.postlike}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="flex items-center space-x-4 justify-between">
                                                <div
                                                    class=" flex-row ml-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-red-200 text-red-700 rounded-full">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-archive mr-2">
                                                        <polyline points="21 8 21 21 3 21 3 8"></polyline>
                                                        <rect x="1" y="3" width="22" height="5"></rect>
                                                        <line x1="10" y1="12" x2="14" y2="12"></line>
                                                    </svg>
                                                    {post.tags}
                                                </div>
                                            </div>

                                            <div class="flex items-center space-x-4 justify-between">
                                                <div class="text-grey-500 flex flex-row space-x-1  my-4">
                                                    <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 24 24" height="1em"
                                                        width="1em" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                    </svg>
                                                    <p class="text-xs">{funGetDateDiff(post.created_at)}</p>
                                                </div>

                                                <div class="flex flex-row space-x-1">
                                                    <a >
                                                        <div
                                                            class="bg-green-500 shadow-lg shadow- shadow-green-600 text-white cursor-pointer px-3 text-center justify-center items-center py-1 rounded-xl flex space-x-2 flex-row">
                                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                                viewBox="0 0 1024 1024" class="text-xl" height="1em" width="1em"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 0 0-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 0 0 471 99.9c-52 0-98 35-111.8 85.1l-85.9 311H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h601.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM184 852V568h81v284h-81zm636.4-353l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 0 1 6.9 27.3c0 22.4-13.2 42.6-33.6 51.8H329V564.8l99.5-360.5a44.1 44.1 0 0 1 42.2-32.3c7.6 0 15.1 2.2 21.1 6.7 9.9 7.4 15.2 18.6 14.6 30.5l-9.6 198.4h314.4C829 418.5 840 436.9 840 456c0 16.5-7.2 32.1-19.6 43z">
                                                                </path>
                                                            </svg>
                                                            <span>{post.likes_count}</span>
                                                        </div>
                                                    </a>

                                                    <a >
                                                        <div
                                                            class="bg-red-500 shadow-lg shadow- shadow-red-600 text-white cursor-pointer px-3 py-1 text-center justify-center items-center rounded-xl flex space-x-2 flex-row">
                                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                                                viewBox="0 0 1024 1024" class="text-xl" height="1em" width="1em"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M885.9 490.3c3.6-12 5.4-24.4 5.4-37 0-28.3-9.3-55.5-26.1-77.7 3.6-12 5.4-24.4 5.4-37 0-28.3-9.3-55.5-26.1-77.7 3.6-12 5.4-24.4 5.4-37 0-51.6-30.7-98.1-78.3-118.4a66.1 66.1 0 0 0-26.5-5.4H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h129.3l85.8 310.8C372.9 889 418.9 924 470.9 924c29.7 0 57.4-11.8 77.9-33.4 20.5-21.5 31-49.7 29.5-79.4l-6-122.9h239.9c12.1 0 23.9-3.2 34.3-9.3 40.4-23.5 65.5-66.1 65.5-111 0-28.3-9.3-55.5-26.1-77.7zM184 456V172h81v284h-81zm627.2 160.4H496.8l9.6 198.4c.6 11.9-4.7 23.1-14.6 30.5-6.1 4.5-13.6 6.8-21.1 6.7a44.28 44.28 0 0 1-42.2-32.3L329 459.2V172h415.4a56.85 56.85 0 0 1 33.6 51.8c0 9.7-2.3 18.9-6.9 27.3l-13.9 25.4 21.9 19a56.76 56.76 0 0 1 19.6 43c0 9.7-2.3 18.9-6.9 27.3l-13.9 25.4 21.9 19a56.76 56.76 0 0 1 19.6 43c0 9.7-2.3 18.9-6.9 27.3l-14 25.5 21.9 19a56.76 56.76 0 0 1 19.6 43c0 19.1-11 37.5-28.8 48.4z">
                                                                </path>
                                                            </svg>
                                                            <span>{post.postunlike}</span>
                                                        </div>
                                                    </a>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className="pt-2 text-base" dangerouslySetInnerHTML={{ __html: post.description }}></div>

                            </section>
                        )}
                    </div>

        </div>
    );
};

export default MainBlog;
