import React, { useEffect, useState } from "react";
import { API_URL } from "../Reuse/Common";
import { useAuth } from "../context/AuthContext";

const Dashboard = () => {
  const { token, logout } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      if (!token) {
        setError("No token found. Redirecting to login.");
        setLoading(false);
        window.location.href = "/login"; 
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/check-session`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        });


        if (response.ok) {
          const data = await response.json();
          setIsAuthenticated(data.loggedIn); 
        } else {
          setError("Session invalid or expired.");
          window.location.href = "/login"; 
        }
      } catch (err) {
        setError("An error occurred while checking the session.");
        window.location.href = "/login"; 
      } finally {
        setLoading(false); 
      }
    };

    checkSession();
  }, [token]); 

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      {isAuthenticated ? <h2>Welcome to your dashboard</h2> : <p>Access Denied</p>}
      <button onClick={logout}>Logout</button>
    </div>
  );
};

export default Dashboard;
