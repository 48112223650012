import React, { useEffect, useState } from 'react';
import Header from './Header';
import Services from './Services';
import Reviews from './Reviews';
import RecentBlogs from './RecentBlogs';
import RecentWork from './RecentWork';
import AddReview from './AddReview';


function HomePage () {

  useEffect(() => {
    document.title = "Home | Kikimo Developers";
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
        <Header />
        <Services />
        <Reviews />
        <AddReview />
        <RecentBlogs />
        <RecentWork />
    </div>
  );
}

export default HomePage ;