import React from 'react';
import navData from '../../assets/nav.json';

const NavpagesPhone = () => {
    // Get the current path
    const currentPath = window.location.pathname;

    return (
        <>
            {navData.map((navItem, index) => (
                <a
                    key={index}
                    href={navItem.link}
                    className={`${
                        currentPath === navItem.link
                            ? 'bg-red-600 text-white' 
                            : 'text-gray-900 hover:bg-red-600 hover:text-white' 
                    } block rounded-md px-3 py-2 text-base font-medium`}
                    aria-current={currentPath === navItem.link ? 'page' : undefined} 
                >
                    {navItem.name}
                </a>
            ))}
        </>
    );
};

export default NavpagesPhone;
