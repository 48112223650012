import React from 'react';
import navData from '../../assets/nav.json';

const Navpages = () => {
    // Get the current path
    const currentPath = window.location.pathname;

    return (
        <>
            {navData.map((navItem, index) => (
                <a 
                key={index}
                href={navItem.link}
                className={`${
                    currentPath === navItem.link ? "text-red-600 border-red-600" : "text-gray-700 border-transparent"
                } flex px-5 items-center py-6 text-sm leading-5 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none transition duration-150 ease-in-out`}>
                
                <span className="mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-compass" width="20"
                  height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                  strokeLinecap="round" strokeLinejoin="round">
                  <path d={navItem.icon} />
                  <path d={navItem.icon2} />
                </svg>
                </span>
                {navItem.name}
                </a>

            ))}
        </>
    );
};

export default Navpages;
