import React from 'react';
import authorImg from '../../assets/user.png';

const BlogCard = ({ data, index }) => {
  return (
    <article
      key={index}
      className="bg-white p-6 mb-6 shadow transition duration-300 group transform hover:-translate-y-2 hover:shadow-2xl rounded-2xl cursor-pointer border"
    >
      <a
        href={`/blog/${data.slug}`}
        className="absolute opacity-0 top-0 right-0 left-0 bottom-0"
        target="_self"
      ></a>
      
      <div className="relative mb-4 rounded-2xl">
        <img
          className="max-h-80 rounded-2xl w-full object-cover transition-transform duration-300 transform group-hover:scale-105"
          src={
            data.imageData
              ? data.imageData
              : `https://staging.kikimodev.com/Image/${data.cover_image}`
          }
          alt={data.title}
        />
        
        <div className="absolute bottom-3 left-3 inline-flex items-center rounded-lg bg-white p-2 shadow-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-5 w-5 text-red-700"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
            />
          </svg>
          <span className="ml-1 text-sm text-slate-400">
            {data.likes_count}
          </span>
        </div>
      </div>
      
      <div className="flex justify-between items-center w-full pb-4 mb-auto">
        <div className="flex items-center">
          <div className="pr-3">
            <img
              className="h-12 w-12 rounded-full object-cover"
              src={authorImg}
              alt={data.name}
            />
          </div>
          
          <div className="flex flex-1">
            <div>
              <p className="text-xs font-semibold">{data.name}</p>
              <p className="text-xs text-gray-500">
                {new Date(data.created_at).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <h3 className="font-medium text-base leading-8">
        <a
          href={`/blog/${data.slug}`}
          className="block relative group-hover:text-red-700 transition-colors duration-200"
        >
          {data.title}
        </a>
      </h3>
    </article>
  );
};

export default BlogCard;
