import React from 'react';

const Modal = ({ layout, handleCloseModal }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
            <div className="bg-white p-5 rounded-xl shadow-lg max-w-lg relative">
                {layout}
                <div className="p-3 text-center mt-2">
                    <button onClick={handleCloseModal}
                        className="bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:bg-red-600">
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
