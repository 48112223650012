import React, { useEffect, useState } from 'react';
import Card from '../Reuse/Card';
import Title from '../Reuse/Title';
import BlurBackground from '../Reuse/BlurBackground';

const servicesData = [
    {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="w-6 text-red-700"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                ></path>
            </svg>
        ),
        title: 'UI/UX Design',
        description: 'Our focus on user-centered design principles ensures that our users are always at the forefront of our minds as we create our designs.',
    },
    {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
                className="w-6 text-red-700"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                ></path>
            </svg>
        ),
        title: 'Web & App Development',
        description: 'Build an atmosphere that creates productivity in your organization and your company culture.',
    },
    {
        icon: (
            <svg
                className="w-6 text-red-700"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="3" y1="9" x2="21" y2="9"></line>
                <line x1="9" y1="21" x2="9" y2="9"></line>
            </svg>
        ),
        title: 'Graphical Design',
        description: 'We develop various types of creative content for advertisements, brochures, or magazines.',
    }
];

const Services = () => {

    useEffect(() => {
        document.title = "Service | Kikimo Developers";
      }, []);

    return (
        <>
            <Title
                title='Our Services'
                description='What We Offer To You !'
            />

            <BlurBackground
                layout={
                    <div className="container mx-auto">
                        <div className="justify-items-center grid md:grid-cols-3 lg:grid-cols-3 gap-4 text-center py-4 mx-auto container">
                            {servicesData.map((service, index) => (
                                <Card
                                    key={index}
                                    icon={service.icon}
                                    title={service.title}
                                    description={service.description}
                                />
                            ))}
                        </div>
                    </div>
                }
            />

        </>
    );
};

export default Services;
