import React, { useState, useEffect } from 'react';
import BlogCard from '../Cards/BlogCard';
import Title from '../Reuse/Title';
import BlurBackground from '../Reuse/BlurBackground';
import { API_URL } from '../Reuse/Common';
import Loader from '../Reuse/Loader';


const RecentBlogs = () => {
    const [posts, setPosts] = useState([]);
    const [showLoadingModal, setShowLoadingModal] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch(`${API_URL}/blogs`);
                const data = await response.json();

                if (data.data) {
                    setPosts(data.data.slice(0, 3));
                } else {
                    setError('Failed to fetch posts');
                }
            } catch (err) {
                setError('An error occurred while fetching the data');
            } finally {
                setShowLoadingModal(false);
            }
        };

        fetchPosts();
    }, []);

    return (
        <div>
            <Title
                title="Recent Blogs"
                description='Our Blogs'
            />

            <BlurBackground
                layout={
                    <div>
                        {showLoadingModal && (
                            <Loader/>
                        )}

                        {error && <p className="text-red-500">{error}</p>}

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {posts.map((data, index) => (
                                <BlogCard key={index} data={data} index={index} />
                            ))}
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default RecentBlogs;
