import React, { useEffect, useState } from 'react';
import liveWorks from '../../assets/livework.json';
import Title from '../Reuse/Title';
import BlurBackground from '../Reuse/BlurBackground';



const LiveWork = () => {

    useEffect(() => {
        document.title = "Live Work | Kikimo Developers";
    }, []);

    return (
        <>
            <Title
                title='Our Live Work'
                description='Where Quality Meets Productivity !'
            />

            <BlurBackground
                layout={
                    <>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {liveWorks.map((livework, index) => (
                            <div class="flex justify-center items-center">
                                <div class="mx-auto">

                                    <div class="relative flex flex-col text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-96">
                                        <div class="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-white bg-clip-border rounded-xl h-96">
                                            <iframe src={livework.link} class="w-full h-full border-none"></iframe>
                                        </div>

                                        <div class="p-6">
                                            <div class="flex items-center justify-between mb-2">
                                                <p class="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-900">
                                                    {livework.name}
                                                </p>
                                                <p class="block font-sans text-base antialiased font-medium leading-relaxed text-red-600 border border-red-600 rounded-lg p-2 hover:bg-red-100">
                                                <a href={livework.link} target="_blank" rel="noopener noreferrer">Demo</a>
                                                </p>
                                            </div>
                                            <p class="block font-sans text-sm antialiased font-normal leading-normal text-gray-700 opacity-75">
                                            {livework.slogan}
                                            </p>
                                        </div>
                                        <div class="p-6 pt-0">
                                            <div class="flex justify-between mb-4">
                                                <div class="w-1/2 pr-2">
                                                    <p class="text-gray-700">Email: <a href={`mailto:${livework.email}`} class="text-blue-600">{livework.email}</a></p>
                                                </div>
                                                <div class="w-1/2 pl-2 text-right">
                                                    <p class="text-gray-700">Contact: <a href={`tel:${livework.contact}`} class="text-blue-600">{livework.contact}</a></p>
                                                </div>
                                            </div>
                                            <a href={`tel:${livework.contact}`}> <button
                                                class="border border-red-600 align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg shadow-gray-900/10 hover:shadow-gray-900/20 focus:opacity-[0.85] active:opacity-[0.85] active:shadow-none block w-full bg-blue-gray-900/10 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                                                type="button"
                                                >
                                                Call Us
                                            </button></a>
                                           
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}

                        </div>
                    </>

                }
            />

        </>
    );
};

export default LiveWork;
