
import React, { useState, useEffect } from 'react';
import { API_URL } from '../Reuse/Common';
import BlurBackground from '../Reuse/BlurBackground';
import MainBlog from './MainBlog';

const SinglePost = () => {
    const [posts, setPosts] = useState([]);
    const [tags, setTags] = useState([]);
    const [showLoadingModal, setShowLoadingModal] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch(`${API_URL}/tags`);
                const data = await response.json();
                if (data) {
                    setPosts(data.posts);
                    setTags(data.tags);
                } else {
                    setError('Failed to fetch posts');
                }
            } catch (err) {
                setError('An error occurred while fetching the data');
            } finally {
                setShowLoadingModal(false);
            }
        };

        fetchPosts();
    }, []);

    return (
        <div>

            <BlurBackground
                layout={
                    <div>
                        {showLoadingModal && (
                            <div class="mx-auto pb-4">
                                <div class="p-4 bg-white border border-primary rounded-md">
                                    <div class="flex">
                                        <div class="mr-4 bg-gray-200 border border-gray-200 h-16 w-16 rounded animate-pulse"></div>
                                        <div class="space-y-1 flex flex-col w-full">
                                            <div class="flex w-full items-center">
                                                <div class="bg-gray-200 border border-gray-200 w-60 h-5 animate-pulse"></div>
                                                <div class="ml-4 bg-ternary w-12 h-5 animate-pulse"></div>
                                            </div>
                                            <div class="bg-gray-200 border border-gray-200 w-36 h-5 animate-pulse"></div>
                                            <div class="bg-gray-200 border border-gray-200 w-full h-44 animate-pulse">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-4 flex items-center justify-between">
                                        <div class="flex items-center gap-2">
                                            <div class="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                                            <span class="bg-tertiary h-1 w-1 rounded animate-pulse"></span>
                                            <div class="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                                        </div>
                                        <div class="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {error && <p className="text-red-500">{error}</p>}

                        {!showLoadingModal && (
                            <>
                                <div class="flex items-center h-6 rounded-t bg-gray-100  text-center text-black" id="headerTerminal">
                                    <div class="flex ml-2 items-center text-center border-red-900 bg-red-500 shadow-inner rounded-full w-3 h-3" id="closebtn">
                                    </div>
                                    <div class="ml-2 border-yellow-900 bg-yellow-500 shadow-inner rounded-full w-3 h-3" id="minbtn">
                                    </div>
                                    <div class="ml-2 border-green-900 bg-green-500 shadow-inner rounded-full w-3 h-3" id="maxbtn">
                                    </div>
                                    <div class="mx-auto pr-16">
                                    </div>
                                </div>

                                <div class="w-12/12 mx-auto bg-white p-5 bg-opacity-1 backdrop-filter backdrop-blur-lg">
                                    <div class="flex lg:flex-row flex-col justify-center item-centerpy-2 bg-transparent">
                                        {/* Column one */}
                                        <div class="lg:w-3/4 rounded-lg">
                                            <div class="flex flex-col">
                                                <MainBlog />
                                            </div>
                                        </div>

                                        {/* Column Two */}
                                        <aside class="lg:w-1/4 bg-transparent rounded-lg  border">
                                            <div class="min-w-screen min-h-auto flex items-center p-2">
                                                <div class="rounded-lg text-white w-[450px]">
                                                    <div class="bg-white p-2 rounded-lg text-center">
                                                        <img src="https://kikimodev.com/static/media/sample-img.90a2a23d26a4a9986a59.jpg" alt="" class="h-20 w-full object-cover content-center rounded-t-lg" />

                                                        <div class="flex justify-center">
                                                            <img src="https://kikimodev.com/static/media/sample-img.90a2a23d26a4a9986a59.jpg" alt="" class="w-20 h-20 rounded-full object-cover content-center -mt-10 border-4 border-white bg-gradient-to-r from-gray-600 via-gray-900 to-gray-600" />
                                                        </div>
                                                        <h1 class="text-center font-bold tracking-wider text-gray-700 mt-4">Morris Muthigani</h1>
                                                        <p class="text-gray-500 mt-1 text-center">Software Engineer</p>
                                                        <br />
                                                        <a href="https://github.com/mmuthigani">
                                                            <button class="bg-gray-900 py-2 px-4 rounded-lg text-white text-sm font-semibold my-4">Github</button></a>

                                                    </div>
                                                </div>

                                            </div>


                                            <div class="flex flex-col p-4">
                                                <div class="rounded">
                                                    <p
                                                        class="border border-l-8 border-red-700  rounded-lg text-base p-2 text-red-700   m-auto font-bold  md:text-xl leading-none text-center shadow ">
                                                        Popular Posts {posts.length}</p>
                                                </div>

                                                <ol class="mt-2">
                                                {posts.map((data, index) => (
                                                    <a target="_self" href={`/blog/${data.slug}`}>
                                                        <li class="hover:bg-gray-300  mb-2 rounded shadow  border ">
                                                            <div class="flex flex-start items-center">
                                                                <div class="flex w-full items-center divide-y divide-dashed font-sans p-2 md:p-2 ">
                                                                <img
                                                                    className="w-16 h-auto mr-2 rounded"
                                                                    src={data.imageData ? data.imageData : `https://staging.kikimodev.com/Image/${data.cover_image}`}
                                                                    alt={data.title}
                                                                    />

                                                                    <div class="flex-1">
                                                                        <p
                                                                            class="text-xs text-cyan-900  hover:underline font-bold leading-none">
                                                                            {data.title}</p>

                                                                        <p class="text-xs text-red-700 ">Published on {new Date(data.created_at).toLocaleDateString()}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </a>
                                                     ))}
                                                </ol>
                                                <div class="max-w-sm rounded overflow-hidden shadow-lg  border ">
                                                    <div class="px-6 py-4">
                                                        <p
                                                            class="border border-l-8 border-red-700  rounded-lg text-base p-2 text-red-700   m-auto font-bold  md:text-xl leading-none text-center shadow ">
                                                            Tags</p>
                                                    </div>
                                                    <div class="px-6 pt-2 pb-2">
                                                    {tags.data.map((data, index) => (
                                                        <span
                                                            class="inline-block bg-gray-200  rounded-full px-3 py-1 text-sm font-semibold text-gray-700  mr-2 mb-2">#{data.tag_name}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                }
            />
        </div>
    );
};

export default SinglePost;
